import { atlasAdminDepartment, atlasAdminTitle } from "@/helpers/selectOptions";
import { IAgency } from "@/store/modules/agency/types";

export type IUserRoleStrings =
  | "admin"
  | "subAdmin"
  | "agent"
  | "claims"
  | "underwriting"
  | "dataEntry"
  | "adjuster"
  | "agencyAdmin"
  | "ezlynx"
  | "billing";

export const userRoleArr: IUserRoleStrings[] = [
  "admin",
  "subAdmin",
  "agent",
  "claims",
  "underwriting",
  "dataEntry",
  "adjuster",
  "agencyAdmin",
  "ezlynx",
  "billing"
];

export type IUserStatusStrings =
  | "pending"
  | "active"
  | "inactive"
  | "pendingApproval"
  | "pendingTutorial"
  | "completedTutorial";
export interface IUserMeta {}
export interface IUserPhone {
  primary: string;
  primaryVerified: {
    verified: boolean;
    verifiedOn: Date;
    verifyCode: string | null;
    verifyInit: Date;
    resendCodeDate: Date;
  };
  secondary: string;
  secondaryVerified: {
    verified: boolean;
    verifiedOn: Date;
    verifyCode: string | null;
    verifyInit: Date;
    resendCodeDate: Date;
  };
}
export interface IAddress {
  unitNumber?: number;
  streetName: string;
  houseNumber: number | undefined;
  streetDirection?: string;
  streetType?: string;
  city: string;
  state: string;
  zipCode: string;
  country?: string;
  line2?: string;
  county?: string;
  isSameAsPhysicalAddress?: boolean;
}

export type isPOBoXFormatTypes = "yes" | "no";
type ValueOf<T> = T[keyof T];
export interface IUserModel {
  _id: string;
  agentCode?: string;
  agencyId: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  password: string;
  status: IUserStatusStrings;
  role: IUserRoleStrings;
  deleted: boolean;
  meta: IUserMeta;
  phone: IUserPhone;
  enableMfa: boolean;
  token: string;
  hasPrimaryCode: boolean;
  hasSecondaryCode: boolean;
  country: string;
  state: string;
  tags: string[];
  physicalAddress: IAddress;
  mailingAddress?: IAddress;
  companyIds: any[];
  isPOBoXFormat: isPOBoXFormatTypes;
  disabledPermissions: string[];
  underWriterAgencies?: string[];
  underWriterAgenciesData?: IAgency[];
  adjusterCode?: string;
  licenseNumber?: string;
  licenseNumberExpirationDate?: Date;
  onboardingTopicsCompleted?: string[];
  isBanned?: boolean;
  isRestricted?: IUserRestricted;
  hasCompletedTutorial?: boolean;
  allAgencyCodes?: string[];
  atlasAdmin?: {
    title: ValueOf<typeof atlasAdminTitle>;
    department: ValueOf<typeof atlasAdminDepartment>;
  };
  isAtlasAdmin?: boolean;
  ezlynxAgencies?: string[];
  adjustingFirm: string;
}

export interface IUserAuthCheck {
  role: IUserRoleStrings;
  status: IUserStatusStrings;
  displayName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  _id: string;
  companiesData: any[];
  companyIds: string[];
  disabledPermissions: string[];
  allAgencyCodes?: string[];
  agentCode: string;
}
export interface IUserRestricted {
  startDate: Date;
  endDate: Date;
}
