
import Vue from "vue";

import UploadArea from "@/components/UploadArea/UploadArea.vue";
import { atlasfileMapState } from "@/store/modules/atlasfile";

interface IData {
  message: string;
  error: string;
  value: string;
}

export default Vue.extend({
  name: "addNewUpload",
  components: {
    UploadArea
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function
    }
  },
  data(): IData {
    return {
      message: "",
      error: "",
      value: ""
    };
  },
  methods: {
    handleModalClose() {
      this.closeModal();
    },
    onFileUploadSuccess(result: any) {
      this.$appNotifySuccess("File Upload Success");
      this.$emit("upload-success", result.response);
      this.closeModal();
    },
    onFileUploadError(err: string) {
      this.error = err;
    }
  },
  computed: {
    ...atlasfileMapState(["makingApiRequest"])
  },
  watch: {
    showModal: {
      immediate: true,
      handler(show: boolean) {
        if (show) {
          this.$modal.show("uploadAttachmentModal");
        } else {
          this.$modal.hide("uploadAttachmentModal");
        }
      }
    }
  }
});
