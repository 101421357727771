enum IDocumentStatus {
  "error",
  "draft",
  "publish",
  "publishing",
  "published",
  "unpublish",
  "unpublishing",
  "removed"
}
export type IDocumentStatusStrings = keyof typeof IDocumentStatus;

export interface IDocumentMeta {}

export interface IDocument {
  _id: string;
  title: string;
  type: string;
  status: IDocumentStatusStrings;
  public: boolean;
  deleted: boolean;
  meta: IDocumentMeta;
  profiles: string[];
  error?: string;
  errorUpload?: string;
  errorProcessing?: string;
  isUploadedExternal: boolean;
  isProcessed: boolean;
  year: number;
  month: number;
}
